import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import { RichText } from "prismic-reactjs"
import React, { FunctionComponent } from "react"
import { ServiceQuery } from "../../graphql-types"
import ContactInfo from "../components/ContactInfo"
import FilterButton from "../components/FilterButton"
import GoBackButton from "../components/GoBackButton"
import Layout from "../components/Layout"
import Map from "../components/Map"
import htmlSerializer from "../utils/html-serializer"
import i18n, { simpleLang } from "../utils/i18n"

export const query = graphql`
  query Service($uid: String!, $lang: String!) {
    prismic {
      service(uid: $uid, lang: $lang) {
        _meta {
          lang
        }
        name
        address
        availability
        description
        email
        location
        phonenumber
        summary
        webpage
        image
        imageSharp {
          id
          picture: childImageSharp {
            main: fluid(maxWidth: 1024, maxHeight: 640, quality: 80) {
              src
              srcSet
              aspectRatio
              sizes
            }
          }
        }
        serviceprovider {
          ... on PRISMIC_Serviceprovider {
            name
            email
            location
            phonenumber
            webpage
            address
            _meta {
              lang
              uid
              type
            }
          }
        }
      }
    }
  }
`

type ServiceProps = {
  data: ServiceQuery
}

const Service: FunctionComponent<ServiceProps> = props => {
  const service = props.data.prismic.service

  if (!service) return null

  const serviceImageUrl = service.imageSharp?.picture?.main!.src

  const serviceName = service.name[0].text
  const serviceSummary = service.summary ? service.summary[0].text : ""

  const serviceProvider = service.serviceprovider
  const serviceProvideName = serviceProvider?.name
    ? serviceProvider?.name[0]?.text
    : ""

  const phoneNumber = service.phonenumber || serviceProvider?.phonenumber
  const email = service.email || serviceProvider?.email
  const webpage = service.webpage || serviceProvider?.webpage
  const address = service.address || serviceProvider?.address
  const location = service.location || serviceProvider?.location
  const placename = serviceProvideName || serviceName

  const title = serviceProvideName
    ? `${serviceProvideName} – ${serviceName}`
    : serviceName

  const lang = simpleLang(service._meta.lang)

  return (
    <Layout
      title={title}
      description={serviceSummary}
      imageUrl={serviceImageUrl}
      lang={lang}
    >
      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="col-start-1 row-start-1 col-span-2">
          <div className="mx-4">
            {service.serviceprovider && (
              <Link
                to={linkResolver({
                  type: serviceProvider!._meta.type,
                  lang: serviceProvider!._meta.lang,
                  uid: serviceProvider!._meta.uid!,
                })}
              >
                {serviceProvider!.name[0].text}
              </Link>
            )}

            <h2>{serviceName}</h2>
          </div>

          {service.imageSharp && (
            <Image
              className="mt-4"
              fluid={service.imageSharp?.picture?.main!}
            />
          )}
        </div>

        <div className="mx-4 col-start-1 row-start-2 col-span-2 lg:col-span-1">
          <div className="font-mono text-xs">{serviceSummary}</div>

          {service.availability && (
            <RichText
              render={service.availability}
              htmlSerializer={htmlSerializer}
            />
          )}

          {service.description && (
            <RichText
              render={service.description}
              htmlSerializer={htmlSerializer}
            />
          )}

          <div className="my-4">
            {service.serviceprovider && (
              <div className="my-4">
                <p className="font-bold">{i18n[lang].fields.serviceProvider}</p>
                <p>
                  <Link
                    to={linkResolver({
                      type: serviceProvider!._meta.type,
                      lang: serviceProvider!._meta.lang,
                      uid: serviceProvider!._meta.uid!,
                    })}
                  >
                    {serviceProvider!.name[0].text}
                  </Link>
                </p>
              </div>
            )}

            {phoneNumber && (
              <ContactInfo
                name={i18n[lang].fields.phoneNumber}
                value={phoneNumber!}
                type="phonenumber"
              />
            )}
            {email && (
              <ContactInfo
                name={i18n[lang].fields.email}
                value={email!}
                type="email"
              />
            )}
            {webpage && (
              <ContactInfo
                name={i18n[lang].fields.webpage}
                value={webpage!}
                type="link"
              />
            )}
            {address && (
              <ContactInfo
                name={i18n[lang].fields.address}
                value={address!}
                type="plain"
              />
            )}
          </div>
        </div>

        {location && (
          <div className="col-start-1 row-start-3 col-span-2 lg:row-start-2 lg:col-start-2 lg:col-span-1">
            <Map
              latitude={location.latitude}
              longitude={location.longitude}
              placeName={placename}
              address={address as string | undefined}
            />
          </div>
        )}
      </div>
      <GoBackButton />
      <FilterButton lang={lang} />
    </Layout>
  )
}

export default Service
